import React, { Component } from 'react'
import { Link } from 'gatsby';
import styled from 'react-emotion'
import './postPreview.css'

function pathToPost(post) {
  return "/blog" + post.slug;
}

export default function({post, isNew}) {
  if(isNew){
    return (
        <div className="item">
        <Link
      to={pathToPost(post)}
      activeClassName="active">{post.frontmatter.title}</Link>
        </div>
    );
  } else{
    return (
        <div className="item">
        <Link
      to={pathToPost(post)}
      activeClassName="active">{post.frontmatter.title}</Link>
        </div>
    );
  }
}
