
import React from 'react'
import styled from 'react-emotion'

import { rhythm } from '../utils/typography'

const PostDate = styled.h4`
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
  color: #8765c1;
  font-weight: 200;
  z-index: 2;
`

export default function DateDisplay({ date }) {
  return (
    <PostDate>
      {date}
    </PostDate>
  )
}
