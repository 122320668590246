import React, { Component } from 'react'
import styled from 'react-emotion'
import './component.css'
import cornerPiece from './cornerPiecePortrai.png';
import cloudTop from './top.png';
import selfPortrait from './smallerSelfie.png';
import PostPreview from './postPreview.js';
import SocialIcons from '../SocialIcons.js';

// return (
//     <React.Fragment>
//     {posts
//      .filter(post => post.node.frontmatter.title.length > 0)
//      .slice(start, end)
//      .map(({ node: post }) => {
//        return (
//            <div key={post.title}>
//            <Preview
//          excerpt={post.frontmatter.excerpt || post.excerpt}
//          date={post.frontmatter.date}
//          title={post.frontmatter.title}
//          to={post.slug}
//            />
//            </div>
//        )
//      })}
// </React.Fragment>
// )

export default function({children, posts}) {
    // const { children } = this.props
    return (
        <div className="cloudContainer">
          <div className="col col1">
            <img className="selfPortrait" src={selfPortrait} />
      <div className="menu">
          {posts.
           map(({ node: post }, ndx) => {
             if(ndx == 0){
               return (
                   <PostPreview isNew={true} key={post.id.toString()} post={post}></PostPreview>
               )
             } else {
               return (
                   <PostPreview key={post.id.toString()} post={post}></PostPreview>
               )
             }
           })}
      </div>
      <SocialIcons />
    </div>
    <div className="col col2">
      <div className="dribble">
      </div>
    <div className="content">
        {children}
    </div>
  </div>        
</div>
    )
  }
